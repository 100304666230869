import { stringify } from 'qs';

import { pagingLimit } from '@/shared/constants/Paging';
import { PageableItems } from '@/shared/types/api';
import { BuildType } from '@/shared/types/redux/ReduxAction';

const baseUsersUrl = 'admin/users';

const getUserAdminUrl = (offset: number, query: { role: string; search: string } | undefined): string =>
  `${baseUsersUrl}?status=PENDING&offset=${offset}&limit=${pagingLimit}&${stringify(query)}`;

export const getRequests = (build: BuildType) => {
  return build.query<PageableItems, any>({
    providesTags: ['GetUserRequests'],
    query: ({ offset, query }) => ({
      url: getUserAdminUrl(offset, query),
      method: 'GET',
    }),
  });
};

export const getRequest = (build: BuildType) => {
  return build.query({
    providesTags: ['GetUserRequest'],
    query: (userId: string) => ({
      url: `${baseUsersUrl}/${userId}`,
      method: 'GET',
    }),
  });
};

export const getCorporateUserRequest = (build: BuildType) => {
  return build.query({
    providesTags: ['GetUserRequest', 'CorporateUserAssets'],
    query: (userId: string) => ({
      url: `${baseUsersUrl}/${userId}/corporate`,
      method: 'GET',
    }),
  });
};

export const getPrivateUserRequest = (build: BuildType) => {
  return build.query({
    providesTags: ['GetUserRequest', 'PrivateUserAssets'],
    query: (userId: string) => ({
      url: `${baseUsersUrl}/${userId}/private`,
      method: 'GET',
    }),
  });
};
