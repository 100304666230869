import { Input, Text, Button } from 'issuix-ui-kit';
import isEmpty from 'lodash/isEmpty';
import { FC, useEffect } from 'react';

import {
  CardContainer,
  CompanyLogo,
  CompanyLogoWrapper,
  DocumentWrapper,
  SectionTitle,
  RequestContainer,
  RequestDataWrapper,
  RequestInfo,
  RequestInfoContainer,
  RequestDataContainer,
  DocumentContainer,
  ActionWrapper,
  UploadFileComponent,
  CardWrapper,
  UploadSection,
  RejectButton,
} from './Request.styled';

import { AcceptModal } from '@/components/AcceptModal/AcceptModal';
import { RejectModal } from '@/components/RejectModal/RejectModal';
import { ASSET_TYPE_ENUM } from '@/enums/AssetTypeEnum';
import { DOCUMENT_UPLOAD_STATUS } from '@/enums/DocumentStatus';
import { STATUS_ENUM } from '@/enums/Status';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse } from '@/shared/types/api';
import { CorporateUserFullResponse } from '@/shared/types/api/models/CorporateUserFullResponse';
import {
  useAssignedAssetUrlMutation,
  useDeleteDocumentMutation,
  useGetCorporateUserAssetsQuery,
} from '@/store/service';
import { findAssetByType } from '@/utils/assets/findAssetByType';
import {
  getDragAndDropDocumentationLabel,
  getViewDocumentationLabel,
} from '@/utils/getDocumentationLabel/getDocumentationLabel';

interface Props {
  user: CorporateUserFullResponse;
  viewDocument: (assets: AssetResponse[], assetType: string) => void;
  getAssetByType: (
    assets: AssetResponse[],
    assetType: string
  ) => AssetResponse | { id: string; fileName: string } | undefined;
  openReject: () => void;
  openAccept: () => void;
  isRejectModalOpen: boolean;
  isAcceptModalOpen: boolean;
  onAcceptModalClose: () => void;
  onAccept: () => void;
  onRejectModalClose: () => void;
  setReason: (reason: string) => void;
  reason: string;
  onReject: () => void;
}

export const CorporateRequestView: FC<Props> = ({
  viewDocument,
  getAssetByType,
  user,
  openReject,
  openAccept,
  isAcceptModalOpen,
  isRejectModalOpen,
  onAcceptModalClose,
  onAccept,
  onRejectModalClose,
  setReason,
  reason,
  onReject,
}) => {
  const { translate } = useTranslation();

  const { data: assets } = useGetCorporateUserAssetsQuery(user.id, { skip: !user });
  const [getImageUrl, { data: imageUrl }] = useAssignedAssetUrlMutation();
  const [deleteDocument] = useDeleteDocumentMutation();

  useEffect(() => {
    const picture = findAssetByType(assets, ASSET_TYPE_ENUM.PICTURE);
    if (assets && assets.length && picture) {
      getImageUrl({ assetId: picture.id });
    }
  }, [assets]);

  if (isEmpty(user)) return null;

  return (
    <RequestContainer>
      <CardContainer>
        <CardWrapper>
          <RequestInfoContainer>
            <SectionTitle>
              <Text size={'extraLarge'} content={translate('request')} />
            </SectionTitle>
            <RequestDataContainer>
              <CompanyLogoWrapper>
                <CompanyLogo src={imageUrl?.url} alt="logo" />
              </CompanyLogoWrapper>
              <RequestDataWrapper>
                <RequestInfo>
                  <Input name={'name'} placeholder={translate('name')} value={user.displayName} disabled />
                  <Input name={'zipCode'} placeholder={translate('zipCode')} value={user.zipCode} disabled />
                  <Input name={'country'} placeholder={translate('country')} value={user.country?.name} disabled />
                  <Input name={'owner'} placeholder={translate('owner')} value={user.corporateUser?.owner} disabled />
                </RequestInfo>
                <RequestInfo>
                  <Input
                    name={'legalForm'}
                    placeholder={'Legal form LLC / Ltd'}
                    value={user.corporateUser?.legalForm?.name}
                    disabled
                  />
                  <Input name={'city'} placeholder={translate('city')} value={user.city} disabled />
                  <Input
                    name={'phoneNumber'}
                    placeholder={translate('phoneNumber')}
                    value={user.phoneNumber}
                    disabled
                  />
                  <Input name={'ceo'} placeholder={translate('CEO')} value={user.corporateUser?.ceo} disabled />
                </RequestInfo>
                <RequestInfo>
                  <Input name={'email'} placeholder={translate('emailAddress')} value={user.email} disabled />
                  <Input name={'Address'} placeholder={translate('address')} value={user.address} disabled />
                  <Input
                    name={'businessFocus'}
                    placeholder={translate('businessFocus')}
                    value={user.corporateUser?.businessFocus?.name}
                    disabled
                  />
                </RequestInfo>
              </RequestDataWrapper>
            </RequestDataContainer>
          </RequestInfoContainer>
          <DocumentContainer>
            <SectionTitle>
              <Text size={'extraLarge'} content={translate('documentation')} />
            </SectionTitle>
            <UploadSection>
              {assets && assets.length ? (
                <DocumentWrapper>
                  <UploadFileComponent
                    label={translate('commercialRegister')}
                    name="commercialRegister"
                    IncorrectFileFormatLabel={translate('incorrectFileFormat')}
                    DragDropFilesHereLabel={getDragAndDropDocumentationLabel(
                      translate,
                      getAssetByType(assets, ASSET_TYPE_ENUM.COMMERCIAL_REGISTER)?.id
                        ? DOCUMENT_UPLOAD_STATUS.UPLOADED
                        : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
                    )}
                    ClickToViewDocumentLabel={getViewDocumentationLabel(
                      translate,
                      getAssetByType(assets, ASSET_TYPE_ENUM.COMMERCIAL_REGISTER)?.id
                        ? DOCUMENT_UPLOAD_STATUS.UPLOADED
                        : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
                    )}
                    value={{
                      fileId: getAssetByType(assets, ASSET_TYPE_ENUM.COMMERCIAL_REGISTER)?.id || '',
                      url: getAssetByType(assets, ASSET_TYPE_ENUM.COMMERCIAL_REGISTER)?.fileName || '',
                    }}
                    onViewDocument={() => viewDocument(assets, ASSET_TYPE_ENUM.COMMERCIAL_REGISTER)}
                    onDelete={() =>
                      deleteDocument({
                        data: {
                          fileId: getAssetByType(assets, ASSET_TYPE_ENUM.COMMERCIAL_REGISTER)?.id,
                          fileName: getAssetByType(assets, ASSET_TYPE_ENUM.COMMERCIAL_REGISTER)?.fileName,
                          isUnassigned: false,
                        },
                      })
                    }
                    edit={!!getAssetByType(assets, ASSET_TYPE_ENUM.COMMERCIAL_REGISTER)?.id}
                  />
                  <UploadFileComponent
                    label={translate('proofOfDomicile')}
                    name="proofOfDomicile"
                    IncorrectFileFormatLabel={translate('incorrectFileFormat')}
                    DragDropFilesHereLabel={getDragAndDropDocumentationLabel(
                      translate,
                      getAssetByType(assets, ASSET_TYPE_ENUM.PROOF_OF_DOMICILE)?.id
                        ? DOCUMENT_UPLOAD_STATUS.UPLOADED
                        : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
                    )}
                    ClickToViewDocumentLabel={getViewDocumentationLabel(
                      translate,
                      getAssetByType(assets, ASSET_TYPE_ENUM.PROOF_OF_DOMICILE)?.id
                        ? DOCUMENT_UPLOAD_STATUS.UPLOADED
                        : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
                    )}
                    value={{
                      fileId: getAssetByType(assets, ASSET_TYPE_ENUM.PROOF_OF_DOMICILE)?.id || '',
                      url: getAssetByType(assets, ASSET_TYPE_ENUM.PROOF_OF_DOMICILE)?.fileName || '',
                    }}
                    onViewDocument={() => viewDocument(assets, ASSET_TYPE_ENUM.PROOF_OF_DOMICILE)}
                    onDelete={() =>
                      deleteDocument({
                        data: {
                          fileId: getAssetByType(assets, ASSET_TYPE_ENUM.PROOF_OF_DOMICILE)?.id,
                          fileName: getAssetByType(assets, ASSET_TYPE_ENUM.PROOF_OF_DOMICILE)?.fileName,
                          isUnassigned: false,
                        },
                      })
                    }
                    edit={!!getAssetByType(assets, ASSET_TYPE_ENUM.PROOF_OF_DOMICILE)?.id}
                  />
                  <UploadFileComponent
                    label={translate('signature')}
                    name="signature"
                    IncorrectFileFormatLabel={translate('incorrectFileFormat')}
                    DragDropFilesHereLabel={getDragAndDropDocumentationLabel(
                      translate,
                      getAssetByType(assets, ASSET_TYPE_ENUM.SIGNATURE)?.id
                        ? DOCUMENT_UPLOAD_STATUS.UPLOADED
                        : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
                    )}
                    ClickToViewDocumentLabel={getViewDocumentationLabel(
                      translate,
                      getAssetByType(assets, ASSET_TYPE_ENUM.SIGNATURE)?.id
                        ? DOCUMENT_UPLOAD_STATUS.UPLOADED
                        : DOCUMENT_UPLOAD_STATUS.NOT_UPLOADED
                    )}
                    value={{
                      fileId: getAssetByType(assets, ASSET_TYPE_ENUM.SIGNATURE)?.id || '',
                      url: getAssetByType(assets, ASSET_TYPE_ENUM.SIGNATURE)?.fileName || '',
                    }}
                    onViewDocument={() => viewDocument(assets, ASSET_TYPE_ENUM.SIGNATURE)}
                    onDelete={() =>
                      deleteDocument({
                        data: {
                          fileId: getAssetByType(assets, ASSET_TYPE_ENUM.SIGNATURE)?.id,
                          fileName: getAssetByType(assets, ASSET_TYPE_ENUM.SIGNATURE)?.fileName,
                          isUnassigned: false,
                        },
                      })
                    }
                    edit={!!getAssetByType(assets, ASSET_TYPE_ENUM.SIGNATURE)?.id}
                  />
                </DocumentWrapper>
              ) : (
                <div>{translate('noDocuments')}</div>
              )}
              <ActionWrapper>
                <Button
                  text="Accept"
                  onClick={openAccept}
                  disabled={!user.isFullyRegistered || user.status !== STATUS_ENUM.PENDING}
                />
                <RejectButton
                  onClick={openReject}
                  text={translate('reject')}
                  buttonType="danger"
                  disabled={!user.isFullyRegistered || user.status !== STATUS_ENUM.PENDING}
                />
              </ActionWrapper>
            </UploadSection>
          </DocumentContainer>
        </CardWrapper>
      </CardContainer>
      <AcceptModal
        isAcceptModalOpen={isAcceptModalOpen}
        onAcceptModalClose={onAcceptModalClose}
        onAccept={onAccept}
        title={translate('approve')}
        subtitle={`${translate('areYouSureUserApprove')} ${user.displayName}?`}
      />
      <RejectModal
        isRejectModalOpen={isRejectModalOpen}
        onRejectModalClose={onRejectModalClose}
        onReject={onReject}
        title={translate('reject')}
        subtitle={`${translate('areYouSureUserReject')} ${user.displayName}?`}
        reason={reason}
        setReason={setReason}
      />
    </RequestContainer>
  );
};
