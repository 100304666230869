import { TFunction } from 'i18next';
import { Button } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import ControlledInput from '@/components/Form/ControlledInput';
import ControlledRadioButtonInput from '@/components/Form/ControlledRadioButtonInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import { useEndUserRoleOptions } from '@/hooks/useEndUserRoleOptions/useEndUserRoleOptions';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useRequestsContext } from '@/view/Admin/Requests/RequestsContext';

const fullyCompletedRequest = (translate: TFunction) => [{ label: translate('fullyCompletedRequest'), value: 'true' }];

const RequestsFilterForm: FC = () => {
  const { translate } = useTranslation();
  const { filteredEndUserRoleOptionsAsDropdown } = useEndUserRoleOptions(translate);

  const {
    reset,
    watch,
    formState: { isDirty },
  } = useFormContext();

  const { setFilters, resetData } = useRequestsContext();

  useEffect(() => {
    watch((value) => {
      resetData();
      setFilters(value);
    });
  }, [watch]);

  return (
    <FilterContainer>
      <InputContainer>
        <ControlledSelect items={filteredEndUserRoleOptionsAsDropdown} placeholder={translate('role')} name={'role'} />
      </InputContainer>
      <InputContainer>
        <ControlledInput placeholder={translate('search')} name={'search'} />
      </InputContainer>
      <InputContainer>
        <ControlledRadioButtonInput
          name={'fullyCompletedRequest'}
          options={fullyCompletedRequest(translate)}
          defaultValue={''}
          description={''}
        />
      </InputContainer>
      <ButtonContainer>
        <Button
          onClick={() => {
            isDirty && reset();
          }}
          text={translate('reset')}
        />
      </ButtonContainer>
    </FilterContainer>
  );
};

export default RequestsFilterForm;

const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

const FilterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 4rem;
  position: relative;
`;

const InputContainer = styled.div`
  width: 20%;
`;
