import { Button } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Documentation } from './Documentation';
import { Column } from './NftCollectionDetailsForm.style';

import ControlledInput from '@/components/Form/ControlledInput';
import ControlledSelectMultiple from '@/components/Form/ControlledSelectMultiple';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import { Row } from '@/components/Layout/Row/Row.styled';
import { useCurrencies } from '@/hooks/useCurrencies/UseCurrencies';
import { useRegions } from '@/hooks/useRegions/UseRegions';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { AssetResponse, NftCollectionResponse } from '@/shared/types/api';
import { parseFormObject } from '@/utils/getNftCollectionFieldValues/getNftCollectionFieldValues';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';

interface NftCollectionDetailsFormProps {
  nftCollection: NftCollectionResponse;
  isEditMode: boolean;
  assets: AssetResponse[];
  productAsset: AssetResponse;
  setIsEditMode: (value: boolean) => void;
}

const NftCollectionDetailsForm: FC<NftCollectionDetailsFormProps> = ({
  nftCollection,
  isEditMode,
  assets,
  productAsset,
  setIsEditMode,
}) => {
  const { translate } = useTranslation();
  const { regionsAsDropdown } = useRegions(translate);
  const { currenciesAsDropdown } = useCurrencies();

  const {
    setValue,
    getValues,
    formState: { dirtyFields, errors },
  } = useFormContext();

  useEffect(() => {
    if (nftCollection) {
      setFieldValues(parseFormObject(nftCollection, assets, productAsset), setValue);
    }
  }, [assets, nftCollection]);

  const onSubmitHandler = async () => {
    console.log('submit');
    setIsEditMode(false);
  };

  const onCancelHandler = () => {
    setIsEditMode(false);
    console.log('cancel');
  };

  return (
    <FormContainer>
      <Row>
        <ControlledInput
          name={'collectionName'}
          placeholder={translate('collectionName')}
          id={'collectionName'}
          disabled={!isEditMode}
        />
        <ControlledInput
          name={'pricePerNft'}
          placeholder={translate('pricePerNft')}
          id={'pricePerNft'}
          disabled={!isEditMode}
          type={'number'}
        />
        <ControlledSelectMultiple
          name={'regions'}
          placeholder={translate('availableTo')}
          items={regionsAsDropdown}
          disabled={!isEditMode}
        />
        <ControlledSelectMultiple
          name={'currencies'}
          items={currenciesAsDropdown}
          disabled={!isEditMode}
          placeholder={translate('sellInCurrency')}
        />
        <ControlledInput
          name={'product'}
          placeholder={translate('productType')}
          id={'product'}
          disabled={!isEditMode}
        />
      </Row>
      <Row>
        <Documentation
          productAsset={{ fileId: productAsset?.id, url: productAsset?.key, type: 'productDocument' }}
          assets={assets}
          isEditMode={isEditMode}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
        />
      </Row>
      <Row>
        <Column>
          <ControlledTextarea
            name={'description'}
            label={translate('collectionDescription')}
            errors={errors}
            disabled={!isEditMode}
          />
        </Column>
        <Column>
          <ControlledTextarea
            name={'germanDescription'}
            label={translate('germanDescription')}
            errors={errors}
            disabled={!isEditMode}
          />
        </Column>
      </Row>
      <PaymentInfo>
        <ControlledInput name={'bankName'} placeholder={translate('bankName')} id={'bankName'} disabled={!isEditMode} />
        <ControlledInput
          name={'bankAddress'}
          placeholder={translate('bankAddress')}
          id={'bankAddress'}
          disabled={!isEditMode}
        />
        <ControlledInput name={'iban'} placeholder={translate('iban')} id={'iban'} disabled={!isEditMode} />
        <ControlledInput
          name={'swift'}
          placeholder={translate('swift').toUpperCase()}
          id={'swift'}
          disabled={!isEditMode}
        />
      </PaymentInfo>
      {isEditMode && (
        <ActionContainer>
          <Button text={translate('cancel')} onClick={onCancelHandler} buttonType={'secondary'} />
          <Button
            text={translate('save')}
            onClick={onSubmitHandler}
            disabled={!!Object.keys(errors).length || !Object.keys(dirtyFields).length}
            buttonType={'primary'}
            isLoading={false}
          />
        </ActionContainer>
      )}
    </FormContainer>
  );
};

export default NftCollectionDetailsForm;

const FormContainer = styled.div`
  position: relative;
  padding: 2rem;
  margin-top: 2rem;
  gap: 1rem;
`;

const ActionContainer = styled.div`
  position: absolute;
  display: flex;
  gap: 2rem;
  right: 2rem;
  bottom: 2rem;
`;

const PaymentInfo = styled(Row)`
  margin-top: 4.4rem;
`;
