import { TFunction } from 'react-i18next';

import { STATUS_ENUM } from '@/enums/Status';

export const translateStatus = (status: string, translate: TFunction): string => {
  switch (status) {
    case STATUS_ENUM.PENDING:
      return translate('pending');
    case STATUS_ENUM.VALIDATING:
      return translate('validating');
    case STATUS_ENUM.ACCEPTED:
      return translate('accepted');
    case STATUS_ENUM.CANCELLED:
      return translate('cancelled');
    case STATUS_ENUM.APPROVED:
      return translate('approved');
    case STATUS_ENUM.CHANGES_REQUESTED:
      return translate('changesRequested');
    case STATUS_ENUM.OPEN:
      return translate('open');
    case STATUS_ENUM.OPENED:
      return translate('open');
    case STATUS_ENUM.STRIPE_CANCELLED:
      return translate('stripeCancelled');
    case STATUS_ENUM.STRIPE_PENDING:
      return translate('stripePending');
    default:
      return 'Invalid status translation';
  }
};
