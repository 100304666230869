import { TFunction } from 'i18next';
import { CurrencyField, Text, Card } from 'issuix-ui-kit';
import { useState, useEffect, useMemo, FC } from 'react';
import { FieldValues, useFormContext, UseFormSetError } from 'react-hook-form';
import styled from 'styled-components';

import ControlledDateInput from '@/components/Form/ControlledDateInput';
import ControlledInput from '@/components/Form/ControlledInput';
import ControllerRadioButtonInput from '@/components/Form/ControlledRadioButtonInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledMultipleSelect from '@/components/Form/ControlledSelectMultiple';
import { Row } from '@/components/Layout/Row/Row.styled';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useCheckIfIsinExistOnBlur } from '@/hooks/useCheckIfIsinExistOnBlur/useCheckIfIsinExistOnBlur';
import { useCouponPaidOptions } from '@/hooks/useCouponPaidOptions/useCouponPaidOptions';
import { useCurrencies } from '@/hooks/useCurrencies/UseCurrencies';
import { useRegions } from '@/hooks/useRegions/UseRegions';
import { useTenantUsers } from '@/hooks/useTenantUsers/useTenantUsers';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { CurrencyResponse, BondResponse } from '@/shared/types/api';
import { getSelectedCurrency, getUserSelector } from '@/store/global/selectors';
import { offeringMonthOptions } from '@/utils/constants/OfferingMonthOptions';
import { formatAmountWithoutRate } from '@/utils/formatAmount/FormatAmount';
import { parseFormObject } from '@/utils/getBondFieldValues/GetBondFieldValues';
import { getFilteredCurrencies } from '@/utils/getFilteredCurrencies/GetFilteredCurrencies';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';

const getListingOptions = (translate: TFunction) => [
  { label: translate('yes'), value: 'true' },
  { label: translate('no'), value: 'false' },
];

interface SmartBondFeaturesProps {
  bond?: BondResponse;
  isView: boolean;
}

const checkIfDenominationIsValid = (value: number, denomination: number, setError: UseFormSetError<FieldValues>) => {
  if (value % denomination !== 0) {
    setError('denomination', { type: 'focus' }, { shouldFocus: true });
  }
};

const CreateSmartBondFeatures: FC<SmartBondFeaturesProps> = ({ bond, isView }) => {
  const [val, setVal] = useState(0);
  const user = useAppSelector((state) => getUserSelector(state));

  const { currencies, currenciesAsDropdown } = useCurrencies();
  const { translate } = useTranslation();
  const { regionsAsDropdown } = useRegions(translate);
  const { couponPaidOptionsAsDropdown } = useCouponPaidOptions(translate);
  const { checkIfIsinExistOnBlur, isinData } = useCheckIfIsinExistOnBlur();
  const { tenantUsersAsDropdown } = useTenantUsers();
  const currency = useAppSelector((state) => getSelectedCurrency(state));

  const {
    setValue,
    getValues,
    watch,
    setError,
    formState: { errors },
  } = useFormContext();

  const watchFields = (fieldNames: string[]) => {
    return watch(fieldNames);
  };

  useEffect(() => {
    if (bond && currency) {
      setFieldValues(parseFormObject({ ...bond }, currency, translate), setValue);
    }
  }, [bond, setValue, currency]);

  const filteredCurrencies: CurrencyResponse[] = useMemo(() => getFilteredCurrencies(currencies), [currencies]);

  const valueWatcher = watchFields(['value', 'isin', 'denomination']);

  useEffect(() => {
    if (valueWatcher.length && valueWatcher[0]) {
      setVal(valueWatcher[0]);
    } else {
      setVal(0);
    }
  }, [valueWatcher]);

  const calculatedValues = useMemo(() => {
    return filteredCurrencies.map((currency) => (
      <Color key={currency.id}>
        <CurrencyField
          key={currency.currencyCode}
          value={+formatAmountWithoutRate(val / currency.rate, currency.decimals)}
          label={currency.currencyCode}
        />
      </Color>
    ));
  }, [filteredCurrencies, val]);

  useEffect(() => {
    if (isinData) setError('isin', { type: 'focus' }, { shouldFocus: true });
  }, [isinData]);

  return (
    <CreateSmartBondFeaturesBox>
      <Text size={'large'} content={translate('createSmartBondFeatures')} />
      <ValueRowWrapper>
        <ValueInputFieldWrapper>
          <ControlledInput
            disabled={isView}
            name={'value'}
            placeholder={translate('faceValue')}
            id={'value'}
            type={'number'}
          />
        </ValueInputFieldWrapper>
        <Text size={'large'} content="=" />
        {calculatedValues}
      </ValueRowWrapper>
      <Row>
        <WideColumn>
          <ControlledMultipleSelect
            name={'sellInCurrencyIds'}
            items={currenciesAsDropdown}
            placeholder={translate('sellInCurrency')}
            disabled={isView}
          />
        </WideColumn>
        <Column>
          <ControlledInput
            name={'denomination'}
            placeholder={translate('denomination')}
            id={'denomination'}
            disabled={isView}
            type={'number'}
            onBlur={() => checkIfDenominationIsValid(valueWatcher[0], valueWatcher[2], setError)}
          />
          <DenominationErrorMessage>{errors.denomination && translate('denominationError')}</DenominationErrorMessage>
        </Column>
        <Column>
          <ControlledInput
            name={'interest'}
            placeholder={translate('interest')}
            id={'interest'}
            disabled={isView}
            type={'number'}
          />
        </Column>
        <Column>
          <IsinWrapper>
            <ControlledInput
              name={'isin'}
              placeholder={translate('isin')}
              id={'isin'}
              onBlur={() => checkIfIsinExistOnBlur(getValues().isin)}
              disabled={isView}
            />
            <IsinErrorMessage>{isinData && translate('isinAlreadyExist')}</IsinErrorMessage>
          </IsinWrapper>
        </Column>
        <Column>
          <ControlledSelect
            name={'couponsPaid'}
            items={couponPaidOptionsAsDropdown}
            placeholder={translate('couponsPaid')}
            disabled={isView}
          />
        </Column>
        <WideColumn>
          <ControlledSelect
            name={'offeringMonths'}
            items={offeringMonthOptions}
            placeholder={translate('offeringDateMonths')}
            disabled={isView}
          />
        </WideColumn>
      </Row>
      <Row>
        <ColumnIssuedDate>
          <ControlledDateInput
            name={'issuedDate'}
            placeholder={translate('issuedDate')}
            disabled={isView}
            id={'issuedDate'}
            minDate={new Date()}
          />
        </ColumnIssuedDate>
        <Column>
          <ControlledInput
            name={'maturity'}
            placeholder={translate('maturityMonths')}
            disabled={isView}
            id={'maturity'}
            type={'number'}
          />
        </Column>
        <Column>
          <ControlledMultipleSelect
            name={'regionIds'}
            items={regionsAsDropdown}
            placeholder={translate('availableTo')}
            disabled={isView}
          />
        </Column>
        <Column>
          {user?.email === 'office@nimbus-tech.io' && (
            <ControlledSelect
              name={'issuer'}
              items={tenantUsersAsDropdown}
              placeholder={translate('issuerName')}
              disabled={isView}
            />
          )}
        </Column>
        <ListingWrapper>
          <ControllerRadioButtonInput
            options={getListingOptions(translate)}
            defaultValue="false"
            description="Listing"
            name="listingOption"
            disabled={isView}
          />
        </ListingWrapper>
      </Row>
    </CreateSmartBondFeaturesBox>
  );
};

export default CreateSmartBondFeatures;

const Color = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CreateSmartBondFeaturesBox = styled(Card)`
  height: max-content !important;
  width: 100%;
  padding: 1rem 2rem;
`;

const IsinWrapper = styled.div`
  width: 100%;
  position: relative;
  & > div {
    margin-bottom: 0;
  }
`;
const IsinErrorMessage = styled.div`
  color: #f4778a;
  font-size: 1.2rem;
  position: absolute;
  right: 0.5rem;
  top: 3.5rem;
`;

const DenominationErrorMessage = styled.div`
  color: #f4778a;
  font-size: 1.2rem;
  position: relative;
  right: 0.5rem;
  top: -1.5rem;
`;

const ValueInputFieldWrapper = styled.div`
  width: 100%;
  display: flex;

  & > div {
    align-self: self-end;
    margin-bottom: 0;
  }
`;

const ValueRowWrapper = styled(Row)`
  margin: 2.2rem 0 4.2rem 0;
`;

const ListingWrapper = styled.div`
  margin-top: -2rem;
  width: 48%;
  & div > div {
    display: flex;
    align-items: flex-end;
    height: 4rem;
  }
`;

const Column = styled.div`
  width: 20%;
`;

const WideColumn = styled.div`
  width: 30%;
`;

const ColumnIssuedDate = styled.div`
  width: 18.5%;
`;
