export enum STATUS_ENUM {
  CANCELLED = 'CANCELLED',
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',
  OPEN = 'OPEN',
  OPENED = 'OPENED',
  APPROVED = 'APPROVED',
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  VALIDATING = 'VALIDATING',
  STRIPE_PENDING = 'STRIPE_PENDING',
  STRIPE_CANCELLED = 'STRIPE_CANCELLED',
}
