import { Button } from 'issuix-ui-kit';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import styled from 'styled-components';

import ControlledInput from '@/components/Form/ControlledInput';
import ControlledRadioButtonInput from '@/components/Form/ControlledRadioButtonInput';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { useTransactionsFromBuyersContext } from '@/view/Admin/TransactionsFromBuyers/TransactionsFromBuyersContext';

const transactionToReview = (translate: TFunction) => [{ label: translate('transactionsForReview'), value: 'true' }];
const transactionPending = (translate: TFunction) => [{ label: translate('transactionsPending'), value: 'true' }];
const TransactionsFromBuyersFilterForm: FC = () => {
  const { translate } = useTranslation();
  const { setFilters, resetData } = useTransactionsFromBuyersContext();
  const {
    reset,
    watch,
    formState: { isDirty },
  } = useFormContext();

  useEffect(() => {
    watch((value) => {
      resetData();
      setFilters(value);
    });
  }, [watch]);

  return (
    <FilterContainer>
      <InputContainer>
        <ControlledRadioButtonInput
          name={'transactionsForReview'}
          options={transactionToReview(translate)}
          defaultValue={''}
          description={''}
        />
      </InputContainer>
      <InputContainer>
        <ControlledInput name={'referenceNumber'} placeholder={translate('referenceNumber')} />
      </InputContainer>
      <InputContainer>
        <ControlledRadioButtonInput
          name={'transactionsPending'}
          options={transactionPending(translate)}
          defaultValue={''}
          description={''}
        />
      </InputContainer>

      <ButtonContainer>
        <Button
          onClick={() => {
            isDirty && reset();
          }}
          text={translate('reset')}
        />
      </ButtonContainer>
    </FilterContainer>
  );
};

export default TransactionsFromBuyersFilterForm;

const FilterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 4rem;
  position: relative;
`;
const InputContainer = styled.div`
  width: 20%;
`;
const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;
