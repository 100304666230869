import { createApi } from '@reduxjs/toolkit/query/react';

import { fullRegistrationCorporateUser } from '../auth/api/registration/fullRegistrationCorporateUser';
import { fullRegistrationPrivateUser } from '../auth/api/registration/fullRegistrationPrivateUser';
import { updateBond } from '../bonds/api/updateBond/updateBond';
import { payViaBankTransfer, payViaCard, payViaCrypto } from '../payment/api/payment';
import { getBusinessFocus } from '../resources/business-focus/api/getBusinessFocus';
import { getCountries } from '../resources/country/api/getCountries';
import { getLegalForms } from '../resources/legal-form/api/getLegalForms';
import { assignShare } from '../shares/api/assignShare/assignShare';
import { getTenantShare } from '../shares/api/getTenantShare/getTenantShare';
import { approveUser } from '../user/api/approve-user/approveUser';
import {
  getCorporateUser,
  getMyCorporateUser,
  getMyPrivateUser,
  getPrivateUser,
  getUserById,
  getUserRole,
} from '../user/api/get-user/getUser';
import { rejectUser } from '../user/api/reject-user/rejectUser';

import { assetAssignedUrl } from '@/store/assets/api/assignedUrl/assetAssignedUrl';
import { deleteDocument } from '@/store/assets/api/deleteDocument/deleteDocument';
import { unassignedAsset } from '@/store/assets/api/unassigned/unassignedAsset';
import { unassignedNftAsset } from '@/store/assets/api/unassignedNft/unassignedNftAsset';
import { unassignedAssetUrl } from '@/store/assets/api/unassignedUrl/unassignedAssetUrl';
import { getChartData } from '@/store/audit/api/getChartData/getChartData';
import { changePassword } from '@/store/auth/api/changePassword/changePassword';
import { forgotPassword } from '@/store/auth/api/forgot-password/forgot-password';
import { login } from '@/store/auth/api/login/login';
import { logout } from '@/store/auth/api/logout/logout';
import {
  checkCorporateNameExists,
  checkEmailExists,
  quickRegistrationCorporateUser,
} from '@/store/auth/api/registration/quickRegistrationCorporateUser';
import { quickRegistrationPrivateUser } from '@/store/auth/api/registration/quickRegistrationPrivateUser';
import { resendEmail } from '@/store/auth/api/registration/resendEmail';
import { resetPasswordWithCode } from '@/store/auth/api/reset-password-with-code/reset-password-with-code';
import { verifyAccount } from '@/store/auth/api/verify-account/verify-account';
import { approveBond } from '@/store/bonds/api/approveBond/approveBond';
import { checkIfIsinExist } from '@/store/bonds/api/checkIfIsinExist/checkIfIsinExist';
import { createBond } from '@/store/bonds/api/createBond/createBond';
import { getBondAssets } from '@/store/bonds/api/getBondAssets/getBondAssets';
import { getBondById } from '@/store/bonds/api/getBondById/getBondById';
import { getBonds } from '@/store/bonds/api/getBonds/getBonds';
import { getTenantBonds } from '@/store/bonds/api/getTenantBonds/getTenantBonds';
import { rejectBond } from '@/store/bonds/api/rejectBond/rejectBond';
import { getBondCalculations } from '@/store/calculations/api/getBondCalculations';
import { getBondPortfolioCalculations } from '@/store/calculations/api/getBondPortfolioCalculations';
import { getSmartSharesLedgerCalculation } from '@/store/calculations/api/getSmartSharesLedgerCalculation';
import { getSmartSharesOffered } from '@/store/calculations/api/getSmartSharesOffered';
import { getUserTotalInvestedAmount } from '@/store/calculations/api/getUserTotalInvestedAmount';
import { approveCase } from '@/store/cases/api/approve-case/approveCase';
import { createCase } from '@/store/cases/api/create-case/createCase';
import { deleteCaseAssets } from '@/store/cases/api/delete-case-assets/deleteCaseAssets';
import { getCaseDetails } from '@/store/cases/api/get-case-details/getCaseDetails';
import { getCasesForRecipient } from '@/store/cases/api/get-cases-for-recipient/getCasesForRecipient';
import { requestChanges } from '@/store/cases/api/request-changes/requestChanges';
import { getCurrencies } from '@/store/currencies/api/getCurrencies/getCurrencies';
import { checkNftCollectionName } from '@/store/nft/api/checkNftCollectionName/checkNftCollectionName';
import { createNft } from '@/store/nft/api/createNft/createNft';
import { createNftCollection } from '@/store/nft/api/createNftCollection/createNftCollection';
import { deleteNftCollection } from '@/store/nft/api/deleteNftCollection/deleteNftCollection';
import { getNftCollectionAcceptedTransactions } from '@/store/nft/api/getNftCollectionAcceptedTransactions/getNftCollectionAcceptedTransactions';
import { getNftCollectionAssets } from '@/store/nft/api/getNftCollectionAssets/getNftCollectionAssets';
import { getNftCollectionHolders } from '@/store/nft/api/getNftCollectionHolders/getNftCollectionHolders';
import { getNftCollectionStatistics } from '@/store/nft/api/getNftCollectionStatistics/getNftCollectonStatistics';
import { getNftCollections } from '@/store/nft/api/getNftCollections/getNftCollections';
import { getNftProductAsset } from '@/store/nft/api/getNftProductAsset/getNftProductAsset';
import { getNftTransactionById } from '@/store/nft/api/getNftTransactionById/getNftTransactionById';
import { getNftsByCollectionId } from '@/store/nft/api/getNftsByCollectionId/getNftsCollections';
import { getNftsByUserId } from '@/store/nft/api/getNftsByUserId/getNftsByUserId';
import { getOrderByTransactionId } from '@/store/nft/api/getOrderByTransactionId/getOrderByTransactionId';
import { getTotalNftsByUserId } from '@/store/nft/api/getTotalNftsByUserId/getTotalNftsByUserId';
import { sendNotification } from '@/store/notification/api/sendNotification/sendNotification';
import { sendNotificationToAll } from '@/store/notification/api/sendNotificationToAll/sendNotificationToAll';
import { getPortfolioChart } from '@/store/portfolio/api/getPortfolioChart/getPortfolioChart';
import { getUserPortfolio } from '@/store/portfolio/api/getUserPortfolio/getUserPortfolio';
import { getAvailabilityOptions } from '@/store/resources/options/api/availabilityOptions/getAvailabilityOptions';
import { getCouponPaidOptions } from '@/store/resources/options/api/couponPaidOptions/getCouponPaidOptions';
import { getEndUserRoleOptions } from '@/store/resources/options/api/endUserRoleOptions/getEndUserRoleOptions';
import { getRatingOptions } from '@/store/resources/options/api/ratingOptions/getRatingOptions';
import { getRoundOptions } from '@/store/resources/options/api/roundOptions/getRoundOptions';
import { getShareTypeOptions } from '@/store/resources/options/api/shareTypeOptions/getShareTypeOptions';
import { getRegions } from '@/store/resources/region/api/getRegions';
import { baseQueryInterceptor } from '@/store/service/interceptor';
import { approveShare } from '@/store/shares/api/approveShare/approveShare';
import { checkIfIsinExistShares } from '@/store/shares/api/checkIfIsinExistShares/checkIfIsinExistShares';
import { createShare } from '@/store/shares/api/createShare/createShare';
import { directEmail } from '@/store/shares/api/directEmail/directEmail';
import { getShareAssets } from '@/store/shares/api/getShareAssets/getShareAssets';
import { getShareById } from '@/store/shares/api/getShareById/getShareById';
import { getShareStatus } from '@/store/shares/api/getShareStatus/getShareStatus';
import { getShares } from '@/store/shares/api/getShares/getShares';
import { rejectShare } from '@/store/shares/api/rejectShare/rejectShare';
import { updateShare } from '@/store/shares/api/updateShare/updateShare';
import { acceptTransaction } from '@/store/transactions/api/acceptTransaction/acceptTransaction';
import { getAcceptedTransactions } from '@/store/transactions/api/acceptedTransactions/acceptedTransactions';
import { changeCouponStatus } from '@/store/transactions/api/changeCouponStatus/changeCouponStatus';
import { getAllIssuedCoupons } from '@/store/transactions/api/getAllIssedCoupons/getAllIssuedCoupons';
import { getAllTransactions } from '@/store/transactions/api/getAllTransactions/getAllTransactions';
import { getBondTransactionById } from '@/store/transactions/api/getBondTransactions/getBondTransactions';
import { getInvestedAmount } from '@/store/transactions/api/getInvestedAmount/getInvestedAmount';
import { getIssuedCouponsByTransactionId } from '@/store/transactions/api/getIssuedCouponsByTransactionId/getIssuedCouponsByTransactionId';
import { getIssuedCouponsByUserId } from '@/store/transactions/api/getIssuedCouponsByUserId/getIssuedCouponsByUserId';
import { getItemTransactions } from '@/store/transactions/api/getItemTransactions/getItemTransactions';
import { getShareTransactionById } from '@/store/transactions/api/getShareTransaction/getShareTransaction';
import {
  getShareholdersLedger,
  getShareholdersPdf,
} from '@/store/transactions/api/getShareholdersLedger/getShareholdersLedger';
import { getSmartShareLedger } from '@/store/transactions/api/getSmartShareLedger/getSmartShareLedger';
import { getTransactionDetails } from '@/store/transactions/api/getTransactionDetails/getTransactionDetails';
import { getTransactionsByUserId } from '@/store/transactions/api/getTransactionsByUserId/getTransactionsByUserId';
import { rejectTransaction } from '@/store/transactions/api/rejectTransaction/rejectTransaction';
import { validateHash } from '@/store/transactions/api/validateHash/validateHash';
import { adminChangePassword } from '@/store/user/api/admin-change-password/adminChangePassword';
import {
  getCorporateUserRequest,
  getPrivateUserRequest,
  getRequest,
  getRequests,
} from '@/store/user/api/get-requests/getRequests';
import { getTenantUsers } from '@/store/user/api/get-tenant-users/getTenantUsers';
import { getCorporateUserAssets, getPrivateUserAssets } from '@/store/user/api/get-user-asset/getUserAssets';
import { getUsers, getAllUsers } from '@/store/user/api/get-users/getUsers';
import { updateUserDetails } from '@/store/user/api/update-user-details/updateUserDetails';

export const api: any = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryInterceptor,
  tagTypes: [
    'CorporateUser',
    'MyCorporateUser',
    'PrivateUser',
    'MyPrivateUser',
    'GetCasesForRecipient',
    'GetCaseDetails',
    'GetTransactionDetails',
    'GetChartData',
    'GetBonds',
    'GetShares',
    'GetUserRequest',
    'GetUserRequests',
    'GetCoupons',
    'GetUsers',
    'PrivateUserAssets',
    'CorporateUserAssets',
  ],
  endpoints: (build) => ({
    getCurrencies: getCurrencies(build),
    login: login(build),
    logout: logout(build),
    verifyAccount: verifyAccount(build),
    quickRegistrationPrivateUser: quickRegistrationPrivateUser(build),
    quickRegistrationCorporateUser: quickRegistrationCorporateUser(build),
    checkEmailExists: checkEmailExists(build),
    resendEmail: resendEmail(build),
    getCountries: getCountries(build),
    getLegalForms: getLegalForms(build),
    getBusinessFocus: getBusinessFocus(build),
    fullRegistrationCorporateUser: fullRegistrationCorporateUser(build),
    fullRegistrationPrivateUser: fullRegistrationPrivateUser(build),
    getUsers: getUsers(build),
    getRequests: getRequests(build),
    checkCorporateNameExists: checkCorporateNameExists(build),
    getRegions: getRegions(build),
    createBond: createBond(build),
    unassignedAsset: unassignedAsset(build),
    unassignedAssetUrl: unassignedAssetUrl(build),
    assignedAssetUrl: assetAssignedUrl(build),
    rejectUser: rejectUser(build),
    approveUser: approveUser(build),
    checkIfIsinExist: checkIfIsinExist(build),
    getBonds: getBonds(build),
    getShares: getShares(build),
    getShareAssets: getShareAssets(build),
    getRequest: getRequest(build),
    getCorporateUserRequest: getCorporateUserRequest(build),
    getPrivateUserRequest: getPrivateUserRequest(build),
    updateShare: updateShare(build),
    getBondById: getBondById(build),
    getBondAssets: getBondAssets(build),
    createShare: createShare(build),
    checkIfIsinExistShares: checkIfIsinExistShares(build),
    getAvailabilityOptions: getAvailabilityOptions(build),
    getCouponPaidOptions: getCouponPaidOptions(build),
    getEndUserRoleOptions: getEndUserRoleOptions(build),
    getRatingOptions: getRatingOptions(build),
    getRoundOptions: getRoundOptions(build),
    getShareTypeOptions: getShareTypeOptions(build),
    forgotPassword: forgotPassword(build),
    resetPasswordWithCode: resetPasswordWithCode(build),
    getPrivateUserAssets: getPrivateUserAssets(build),
    getCorporateUserAssets: getCorporateUserAssets(build),
    getCorporateUser: getCorporateUser(build),
    getPrivateUser: getPrivateUser(build),
    getUserRole: getUserRole(build),
    getUserById: getUserById(build),
    getMyCorporateUser: getMyCorporateUser(build),
    getMyPrivateUser: getMyPrivateUser(build),
    updateUserDetails: updateUserDetails(build),
    adminChangePassword: adminChangePassword(build),
    updateBond: updateBond(build),
    getCasesForRecipient: getCasesForRecipient(build),
    createCase: createCase(build),
    getCaseDetails: getCaseDetails(build),
    assignShare: assignShare(build),
    getAllUsers: getAllUsers(build),
    approveCase: approveCase(build),
    requestChanges: requestChanges(build),
    getAllIssuedCoupons: getAllIssuedCoupons(build),
    getAllTransactions: getAllTransactions(build),
    deleteCaseAssets: deleteCaseAssets(build),
    getItemTransactions: getItemTransactions(build),
    getBondTransactionById: getBondTransactionById(build),
    getIssuedCouponsByTransactionId: getIssuedCouponsByTransactionId(build),
    getTransactionDetails: getTransactionDetails(build),
    acceptTransaction: acceptTransaction(build),
    rejectTransaction: rejectTransaction(build),
    validateHash: validateHash(build),
    getShareTransactionById: getShareTransactionById(build),
    getAcceptedTransactions: getAcceptedTransactions(build),
    getTransactionsByUserId: getTransactionsByUserId(build),
    getIssuedCouponsByUserId: getIssuedCouponsByUserId(build),
    getShareById: getShareById(build),
    getChartData: getChartData(build),
    getUserPortfolio: getUserPortfolio(build),
    getPortfolioChart: getPortfolioChart(build),
    getUserTotalInvestedAmount: getUserTotalInvestedAmount(build),
    getSmartShareLedger: getSmartShareLedger(build),
    getSmartSharesLedgerCalculation: getSmartSharesLedgerCalculation(build),
    getShareholdersLedger: getShareholdersLedger(build),
    approveBond: approveBond(build),
    rejectBond: rejectBond(build),
    approveShare: approveShare(build),
    rejectShare: rejectShare(build),
    getBondCalculations: getBondCalculations(build),
    payViaCard: payViaCard(build),
    payViaCrypto: payViaCrypto(build),
    payViaBankTransfer: payViaBankTransfer(build),
    getInvestedAmount: getInvestedAmount(build),
    changeCouponStatus: changeCouponStatus(build),
    getShareholdersPdf: getShareholdersPdf(build),
    directEmail: directEmail(build),
    sendNotification: sendNotification(build),
    sendNotificationToAll: sendNotificationToAll(build),
    changePassword: changePassword(build),
    getTenantShare: getTenantShare(build),
    getShareStatus: getShareStatus(build),
    getTenantBonds: getTenantBonds(build),
    getBondPortfolioCalculations: getBondPortfolioCalculations(build),
    getSmartSharesOffered: getSmartSharesOffered(build),
    unassignedNftAsset: unassignedNftAsset(build),
    createNft: createNft(build),
    getNftCollections: getNftCollections(build),
    createNftCollection: createNftCollection(build),
    getNftCollectionAssets: getNftCollectionAssets(build),
    getNftProductAsset: getNftProductAsset(build),
    getNftsByCollectionId: getNftsByCollectionId(build),
    checkNftCollectionName: checkNftCollectionName(build),
    getNftCollectionAcceptedTransactions: getNftCollectionAcceptedTransactions(build),
    getNftsByUserId: getNftsByUserId(build),
    getNftCollectionHolders: getNftCollectionHolders(build),
    getNftCollectionStatistics: getNftCollectionStatistics(build),
    getTotalNftsByUserId: getTotalNftsByUserId(build),
    getNftTransactionById: getNftTransactionById(build),
    deleteNftCollection: deleteNftCollection(build),
    getOrderByTransactionId: getOrderByTransactionId(build),
    getTenantUsers: getTenantUsers(build),
    deleteDocument: deleteDocument(build),
  }),
});

export const {
  useGetCurrenciesQuery,
  useLoginMutation,
  useLogoutMutation,
  useVerifyAccountMutation,
  useQuickRegistrationPrivateUserMutation,
  useQuickRegistrationCorporateUserMutation,
  useCheckEmailExistsMutation,
  useResendEmailMutation,
  useGetCountriesQuery,
  useGetLegalFormsQuery,
  useGetBusinessFocusQuery,
  useFullRegistrationCorporateUserMutation,
  useFullRegistrationPrivateUserMutation,
  useGetUsersQuery,
  useGetRequestsQuery,
  useCheckCorporateNameExistsMutation,
  useGetRegionsQuery,
  useCreateBondMutation,
  useUnassignedAssetMutation,
  useUnassignedAssetUrlMutation,
  useRejectUserMutation,
  useApproveUserMutation,
  useCheckIfIsinExistMutation,
  useGetBondsQuery,
  useGetSharesQuery,
  useGetShareAssetsQuery,
  useGetRequestQuery,
  useAssignedAssetUrlMutation,
  useGetBondByIdQuery,
  useGetBondAssetsQuery,
  useUpdateShareMutation,
  useCreateShareMutation,
  useCheckIfIsinExistSharesMutation,
  useGetCorporateUserRequestQuery,
  useGetPrivateUserRequestQuery,
  useGetPrivateUserAssetsQuery,
  useGetCorporateUserAssetsQuery,
  useGetAvailabilityOptionsQuery,
  useGetCouponPaidOptionsQuery,
  useGetEndUserRoleOptionsQuery,
  useGetRatingOptionsQuery,
  useGetRoundOptionsQuery,
  useGetShareTypeOptionsQuery,
  useForgotPasswordMutation,
  useResetPasswordWithCodeMutation,
  useGetCorporateUserQuery,
  useGetPrivateUserQuery,
  useGetMyCorporateUserQuery,
  useGetMyPrivateUserQuery,
  useGetUserByIdQuery,
  useUpdateUserDetailsMutation,
  useAdminChangePasswordMutation,
  useUpdateBondMutation,
  useGetCasesForRecipientQuery,
  useCreateCaseMutation,
  useGetCaseDetailsQuery,
  useAssignShareMutation,
  useGetAllUsersQuery,
  useApproveCaseMutation,
  useRequestChangesMutation,
  useGetAllIssuedCouponsQuery,
  useGetAllTransactionsQuery,
  useDeleteCaseAssetsMutation,
  useGetItemTransactionsQuery,
  useGetBondTransactionByIdQuery,
  useGetIssuedCouponsByTransactionIdQuery,
  useGetTransactionDetailsQuery,
  useAcceptTransactionMutation,
  useRejectTransactionMutation,
  useValidateHashMutation,
  useGetShareTransactionByIdQuery,
  useGetAcceptedTransactionsQuery,
  useGetTransactionsByUserIdQuery,
  useGetIssuedCouponsByUserIdQuery,
  useGetShareByIdQuery,
  useGetChartDataQuery,
  useGetUserPortfolioQuery,
  useGetPortfolioChartQuery,
  useGetUserTotalInvestedAmountQuery,
  useGetUserRoleQuery,
  useGetSmartShareLedgerQuery,
  useGetSmartSharesLedgerCalculationQuery,
  useGetShareholdersLedgerQuery,
  useRejectBondMutation,
  useApproveBondMutation,
  useRejectShareMutation,
  useApproveShareMutation,
  useGetBondCalculationsQuery,
  usePayViaCardMutation,
  usePayViaCryptoMutation,
  usePayViaBankTransferMutation,
  useGetInvestedAmountQuery,
  useChangeCouponStatusMutation,
  useLazyGetShareholdersPdfQuery,
  useDirectEmailMutation,
  useSendNotificationMutation,
  useSendNotificationToAllMutation,
  useChangePasswordMutation,
  useGetTenantShareQuery,
  useGetShareStatusQuery,
  useGetTenantBondsQuery,
  useGetBondPortfolioCalculationsQuery,
  useGetSmartSharesOfferedQuery,
  useUnassignedNftAssetMutation,
  useCreateNftMutation,
  useGetNftCollectionsQuery,
  useCreateNftCollectionMutation,
  useGetNftCollectionAssetsQuery,
  useGetNftProductAssetQuery,
  useGetNftsByCollectionIdQuery,
  useGetNftsByUserIdQuery,
  useCheckNftCollectionNameMutation,
  useGetNftCollectionAcceptedTransactionsQuery,
  useGetNftCollectionHoldersQuery,
  useGetNftCollectionStatisticsQuery,
  useGetTotalNftsByUserIdQuery,
  useGetNftTransactionByIdQuery,
  useDeleteNftCollectionMutation,
  useGetOrderByTransactionIdQuery,
  useGetTenantUsersQuery,
  useDeleteDocumentMutation,
} = api;
