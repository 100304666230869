export enum ASSET_TYPE_ENUM {
  PROOF_OF_DOMICILE = 'proof of domicile',
  SIGNATURE = 'signature',
  PASSPORT = 'passport',
  COMMERCIAL_REGISTER = 'commercial register',
  IMAGE = 'image',
  PICTURE = 'picture',
  ID_CARD_FRONT = 'front side',
  ID_CARD_BACK = 'back side',
}

export enum ASSET_CAMEL_CASE_TYPE_ENUM {
  PROOF_OF_DOMICILE = 'proofOfDomicile',
  SIGNATURE = 'signature',
  PASSPORT = 'passport',
  COMMERCIAL_REGISTER = 'commercialRegister',
  IMAGE = 'image',
  PICTURE = 'picture',
  ID_CARD_FRONT = 'frontSide',
  ID_CARD_BACK = 'backSide',
}
