import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const createNftCollectionSchema = (translate: TFunction) =>
  Yup.object().shape({
    name: Yup.string().required(translate('required')),
    price: Yup.string()
      .matches(/^[0-9]+$/, translate('digits'))
      .required(translate('required')),
    productTitle: Yup.string().required(translate('required')),
    content1: Yup.string(),
    content2: Yup.string(),
    content3: Yup.string(),
    content4: Yup.string(),
    content5: Yup.string(),
    issuer: Yup.string(),
    description: Yup.string().required(translate('required')),
    germanDescription: Yup.string().required(translate('required')),
    productDocument: Yup.object().required(translate('required')),
    logo: Yup.object().required(translate('required')),
    banner: Yup.object().required(translate('required')),
    bankName: Yup.string().required(translate('required')),
    bankAddress: Yup.string().required(translate('required')),
    iban: Yup.string().required(translate('required')),
    swift: Yup.string().required(translate('required')),
    regions: Yup.array().of(Yup.string()).nullable().min(1).required(translate('required')),
    currencyIds: Yup.array().of(Yup.string()).nullable().min(1).required(translate('required')),
  });
