import { Button, IColumn, Table, Text, ColoredStatus } from 'issuix-ui-kit';
import { useNavigate } from 'react-router-dom';

import { TransactionsFromBuyersFilterSchema } from './FilterForm/TransactionsFromBuyersFilterSchema';

import ControlledForm from '@/components/Form/ControlledForm';
import { STATUS_ENUM } from '@/enums/Status';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { composePath } from '@/utils/composePath/ComposePath';
import { formatDate } from '@/utils/date/FormatDate';
import { featureHashColumn } from '@/utils/featuresFlags/featureFlags';
import { ClientRoutes } from '@/utils/routes/ClientRoutes';
import { translateStatus } from '@/utils/translateStatus/translateStatus';
import TransactionsFromBuyersFilterForm from '@/view/Admin/TransactionsFromBuyers/FilterForm/TransactionsFromBuyersFilterForm';
import {
  CardHeader,
  FiltersContainer,
  TableContainer,
  TransactionsContainer,
  TransactionsFromBuyersCard,
} from '@/view/Admin/TransactionsFromBuyers/TransactionsFromBuyers.styles';
import {
  TransactionsFromBuyersContextProvider,
  useTransactionsFromBuyersContext,
} from '@/view/Admin/TransactionsFromBuyers/TransactionsFromBuyersContext';

const TransactionsFromBuyers = () => {
  return (
    <TransactionsFromBuyersContextProvider>
      <TransactionsFromBuyersView />
    </TransactionsFromBuyersContextProvider>
  );
};
const TransactionsFromBuyersView = () => {
  const { translate } = useTranslation();
  const { data, fetch } = useTransactionsFromBuyersContext();
  const navigate = useNavigate();

  const onActionClick = (id: string) => {
    navigate(composePath(ClientRoutes.Transactions.detailInfo, { id: id }));
  };

  const tableDef: IColumn[] = [
    {
      name: 'productType',
      width: 1,
      header: {
        label: translate('productType').toUpperCase(),
      },
    },
    {
      name: 'time',
      width: 2,
      header: {
        label: translate('time').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <p>{formatDate(item.time)}</p>;
        },
      },
    },
    {
      name: 'buyerName',
      width: 2,
      header: {
        label: translate('buyerName').toUpperCase(),
      },
    },
    {
      name: 'detectedAmount',
      width: 2.5,
      header: {
        label: translate('price').toUpperCase(),
      },
    },
    {
      name: 'hash',
      width: 4.5,
      header: {
        label: translate('hash').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <p>{item.hash ? item.hash : '/'}</p>;
        },
      },
    },
    {
      name: 'status',
      width: 2,
      header: {
        label: translate('status').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <ColoredStatus value={item.status} translatedValue={translateStatus(item.status, translate)} />;
        },
      },
    },
    {
      name: 'actions',
      width: 2,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          if (item.status !== STATUS_ENUM.STRIPE_PENDING && item.status !== STATUS_ENUM.STRIPE_CANCELLED) {
            return (
              <Button fullyRounded onClick={() => onActionClick(item.id)} text={translate('view')} size={'medium'} />
            );
          } else {
            return <></>;
          }
        },
      },
    },
  ];

  const tableDefWithoutHash: IColumn[] = [
    {
      name: 'productType',
      width: 1,
      header: {
        label: translate('productType').toUpperCase(),
      },
    },
    {
      name: 'time',
      width: 2,
      header: {
        label: translate('time').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <p>{formatDate(item.time)}</p>;
        },
      },
    },
    {
      name: 'buyerName',
      width: 2,
      header: {
        label: translate('buyerName').toUpperCase(),
      },
    },
    {
      name: 'detectedAmount',
      width: 2.5,
      header: {
        label: translate('detectedAmount').toUpperCase(),
      },
    },
    {
      name: 'status',
      width: 2,
      header: {
        label: translate('status').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          return <ColoredStatus value={item.status} translatedValue={translateStatus(item.status, translate)} />;
        },
      },
    },
    {
      name: 'actions',
      width: 2,
      header: {
        label: translate('actions').toUpperCase(),
      },
      column: {
        render: (item: any) => {
          if (item.status !== STATUS_ENUM.STRIPE_PENDING && item.status !== STATUS_ENUM.STRIPE_CANCELLED) {
            return (
              <Button fullyRounded onClick={() => onActionClick(item.id)} text={translate('view')} size={'medium'} />
            );
          } else {
            return <></>;
          }
        },
      },
    },
  ];

  return (
    <TransactionsContainer>
      <TransactionsFromBuyersCard>
        <CardHeader>
          <Text content={translate('transactionsFromBuyers')} size="large" />
        </CardHeader>
        <TableContainer>
          <Table data={data} fetchTrigger={fetch} tableDef={featureHashColumn ? tableDef : tableDefWithoutHash} />
        </TableContainer>
      </TransactionsFromBuyersCard>
      <FiltersContainer>
        <Text content={translate('filter')} size={'extraLarge'} />
        <ControlledForm schema={TransactionsFromBuyersFilterSchema()}>
          <TransactionsFromBuyersFilterForm />
        </ControlledForm>
      </FiltersContainer>
    </TransactionsContainer>
  );
};

export default TransactionsFromBuyers;
