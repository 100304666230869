import { Card, CurrencyField, Text } from 'issuix-ui-kit';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import ControlledDateInput from '@/components/Form/ControlledDateInput';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledMultipleSelect from '@/components/Form/ControlledSelectMultiple';
import { Row } from '@/components/Layout/Row/Row.styled';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useCheckIfIsinExistSharesOnBlur } from '@/hooks/useCheckIfIsinExistOnBlur/useCheckIfIsinExistOnBlur';
import { useCurrencies } from '@/hooks/useCurrencies/UseCurrencies';
import { useRegions } from '@/hooks/useRegions/UseRegions';
import { useRoundOptions } from '@/hooks/useRoundOptions/useRoundOptions';
import { useShareTypeOptions } from '@/hooks/useShareTypeOptions/useShareTypeOptions';
import { useTenantUsers } from '@/hooks/useTenantUsers/useTenantUsers';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { CurrencyResponse } from '@/shared/types/api';
import { getUserSelector } from '@/store/global/selectors';
import { offeringMonthOptions } from '@/utils/constants/OfferingMonthOptions';
import { formatAmountWithoutRate } from '@/utils/formatAmount/FormatAmount';
import { getFilteredCurrencies } from '@/utils/getFilteredCurrencies/GetFilteredCurrencies';

const CreateSmartShareFeatures = () => {
  const [price, setPrice] = useState<number>(0);

  const { currencies, currenciesAsDropdown } = useCurrencies();
  const { translate } = useTranslation();
  const { regionsAsDropdown } = useRegions(translate);
  const { shareTypeOptionsAsDropdown } = useShareTypeOptions(translate);
  const { roundOptionsAsDropdown } = useRoundOptions();
  const { checkIfIsinExistOnBlur, data } = useCheckIfIsinExistSharesOnBlur();
  const { tenantUsersAsDropdown } = useTenantUsers();

  const { watch, getValues } = useFormContext();
  const user = useAppSelector((state) => getUserSelector(state));

  const watchFields = (fieldNames: string[]) => {
    return watch(fieldNames);
  };

  const filteredCurrencies: CurrencyResponse[] = useMemo(() => getFilteredCurrencies(currencies), [currencies]);

  const valueWatcher = watchFields(['price']);

  useEffect(() => {
    if (valueWatcher.length && valueWatcher[0]) {
      setPrice(valueWatcher[0]);
    } else {
      setPrice(0);
    }
  }, [valueWatcher]);

  const calculatedValues = useMemo(() => {
    return filteredCurrencies.map((currency) => (
      <Color key={currency.id}>
        <CurrencyField
          key={currency.currencyCode}
          value={+formatAmountWithoutRate(price / currency.rate, currency.decimals)}
          label={currency.currencyCode}
        />
      </Color>
    ));
  }, [filteredCurrencies, price]);

  return (
    <CreateSmartShareFeaturesBox>
      <Text size={'large'} content={translate('createSmartShareFeatures')} />
      <ValueRowWrapper>
        <ValueInputFieldWrapper>
          <ControlledInput name={'price'} placeholder={translate('pricePerShare')} id={'price'} />
        </ValueInputFieldWrapper>
        <Text size={'large'} content="=" />
        {calculatedValues}
      </ValueRowWrapper>
      <Row>
        <ControlledInput
          name={'totalAmount'}
          placeholder={translate('totalAmount')}
          id={'totalAmount'}
          type={'number'}
        />
        <ControlledInput
          name={'availableForSale'}
          placeholder={translate('availableForSale')}
          id={'availableForSale'}
          type={'number'}
        />
        <ControlledMultipleSelect
          name={'sellInCurrencyIds'}
          items={currenciesAsDropdown}
          placeholder={translate('sellInCurrency')}
        />
        <IsinWrapper>
          <ControlledInput
            name={'isin'}
            placeholder={translate('isin')}
            id={'isin'}
            onBlur={() => checkIfIsinExistOnBlur(getValues().isin)}
          />
          <IsinErrorMessage>{data && translate('isinAlreadyExist')}</IsinErrorMessage>
        </IsinWrapper>
        <ControlledInput
          name={'shareCapital'}
          placeholder={translate('totalEquity')}
          id={'shareCapital'}
          type={'number'}
        />
        <ControlledDateInput name={'issuedDate'} placeholder={translate('issuedDate')} id={'issuedDate'} />
      </Row>
      <Row>
        <Column>
          <ControlledSelect items={shareTypeOptionsAsDropdown} name={'type'} placeholder={translate('type')} />
        </Column>
        <Column>
          <ControlledSelect items={roundOptionsAsDropdown} name={'round'} placeholder={translate('round')} />
        </Column>
        <WideColumn>
          <ControlledSelect
            items={offeringMonthOptions}
            name={'roundDuration'}
            placeholder={translate('roundDuration')}
          />
        </WideColumn>
        <Column>
          <ControlledInput
            name={'minimalInvestment'}
            placeholder={translate('minimalInvestment')}
            id={'minimalInvestment'}
            type={'number'}
          />
        </Column>
        <Column>
          <ControlledMultipleSelect
            name={'regionIds'}
            items={regionsAsDropdown}
            placeholder={translate('availableTo')}
          />
        </Column>
        <Column>
          {user?.email === 'office@nimbus-tech.io' && (
            <ControlledSelect name={'issuer'} items={tenantUsersAsDropdown} placeholder={translate('issuerName')} />
          )}
        </Column>
      </Row>
    </CreateSmartShareFeaturesBox>
  );
};

export default CreateSmartShareFeatures;

const Color = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CreateSmartShareFeaturesBox = styled(Card)`
  height: max-content;
  width: 100%;
  padding: 1rem 2rem;
`;

const Column = styled.div`
  width: 20%;
`;

const WideColumn = styled.div`
  width: 30%;
`;

const IsinWrapper = styled.div`
  width: 100%;
  position: relative;
  & > div {
    margin-bottom: 0;
  }
`;
const IsinErrorMessage = styled.div`
  color: #f4778a;
  font-size: 1.2rem;
  position: absolute;
  right: 0.5rem;
  top: 3.5rem;
`;

const ValueInputFieldWrapper = styled.div`
  width: 100%;
  display: flex;

  & > div {
    align-self: self-end;
    margin-bottom: 0;
  }
`;

const ValueRowWrapper = styled(Row)`
  margin: 2.2rem 0 4.2rem 0;
`;
