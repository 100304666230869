import { LISTING_TYPE_ENUM } from '@/enums/ListingType';
import { CreateBondRequest } from '@/shared/types/api';

export const createBondMapper = (bond: any): CreateBondRequest => {
  return {
    value: +bond.value,
    sellInCurrencyIds: bond.sellInCurrencyIds,
    denomination: +bond.denomination,
    interest: bond.interest,
    isin: bond.isin,
    couponsPaid: bond.couponsPaid,
    offeringMonths: +bond.offeringMonths,
    issuedDate: new Date(bond.issuedDate).toString(),
    maturity: +bond.maturity,
    regionIds: bond.regionIds,
    listingOption: bond.listingOption === 'true',
    bankName: bond.bankName,
    bankAddress: bond.bankAddress,
    iban: bond.iban,
    swift: bond.swift,
    businessPlan: bond.businessPlan,
    presentation: bond.presentation,
    secondPartyOpinion: bond.secondPartyOpinion,
    evaluation: bond.evaluation,
    onePager: bond.onePager,
    whitePaper: bond.whitePaper,
    issuer: bond.issuer,
    insuranceOption: bond.insuranceOption === '' ? false : bond.insuranceOption === 'true',
    secondPartyOpinionOption: bond.secondPartyOpinionOption === '' ? false : bond.secondPartyOpinion === 'true',
    brokerageOption: bond.brokerageOption === '' ? false : bond.brokerageOption === 'true',
    advisoryOption: bond.advisoryOption === '' ? false : bond.advisoryOption === 'true',
    storageOption: bond.storageOption === '' ? false : bond.storageOption === 'true',
    underwritingOption: bond.underwritingOption === '' ? false : bond.underwritingOption === 'true',
    listingType: LISTING_TYPE_ENUM.STANDARD,
  };
};
