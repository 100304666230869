import { BuildType } from '@/shared/types/redux/ReduxAction';

const deleteDocumentPath = `assets/delete-document`;

export const deleteDocument = (build: BuildType) => {
  return build.mutation<void, any>({
    invalidatesTags: ['PrivateUserAssets', 'CorporateUserAssets'],
    query: ({ data }) => ({
      url: deleteDocumentPath,
      method: 'DELETE',
      body: data,
    }),
  });
};
