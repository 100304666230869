import { Button } from 'issuix-ui-kit';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { ActionContainer, FormContainer, TextAreaWrapper, ErrorMessage } from './CreateNftCollectionForm.styles';

import ControlledInput from '@/components/Form/ControlledInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledMultipleSelect from '@/components/Form/ControlledSelectMultiple';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import { Row } from '@/components/Layout/Row/Row.styled';
import { useAppSelector } from '@/hooks/useAppSelector/UseAppSelector';
import { useCheckNftCollectionNameOnBlur } from '@/hooks/useCheckNftCollectionNameOnBlur/useCheckNftCollectionNameOnBlur';
import { useCurrencies } from '@/hooks/useCurrencies/UseCurrencies';
import { useRegions } from '@/hooks/useRegions/UseRegions';
import { useTenantUsers } from '@/hooks/useTenantUsers/useTenantUsers';
import { useTranslation } from '@/hooks/useTranslation/UseTranslationHook';
import { CreateNftCollectionRequest, NftCollectionResponse } from '@/shared/types/api';
import { getUserSelector } from '@/store/global/selectors';
import {
  useCreateNftCollectionMutation,
  useGetNftCollectionAssetsQuery,
  useGetNftProductAssetQuery,
} from '@/store/service';
import { parseFormAssetNftCollectionObject } from '@/utils/parseFormAssetNftCollectionObject/parseFormAssetNftCollectionObject';
import { setFieldValues } from '@/utils/setFieldValues/setFieldValues';
import { useCreateNftCollectionContext } from '@/view/Core/CreateNftCollection/CreateNftCollectionContext';
import { createNftCollectionMapper } from '@/view/Core/CreateNftCollection/CreateNftCollectionForm/CreateNftCollectionMapper';
import { Documentation } from '@/view/Core/CreateNftCollection/CreateNftCollectionForm/Documentation';

export const Form = () => {
  const { translate } = useTranslation();
  const [isSubmitButtonAvailable, setIsSubmitButtonAvailable] = useState<boolean>(true);
  const [isEditDocumentsMode, setIsEditDocumentsMode] = useState<boolean>(true);
  const { regionsAsDropdown } = useRegions(translate);
  const { currenciesAsDropdown } = useCurrencies();
  const { tenantUsersAsDropdown } = useTenantUsers();
  const user = useAppSelector((state) => getUserSelector(state));

  const {
    handleSubmit,
    setValue,
    setError,
    getValues,
    formState: { dirtyFields, errors, isValid },
  } = useFormContext();

  const { nftCollectionId, nftProductId, setNftCollectionId, setNftProductId } = useCreateNftCollectionContext();
  const { checkIfNftCollectionNameExistsOnBlur, checkName } = useCheckNftCollectionNameOnBlur();

  const { data: collectionAssets } = useGetNftCollectionAssetsQuery(
    { query: { id: nftCollectionId } },
    { skip: !nftCollectionId }
  );

  const { data: productAsset } = useGetNftProductAssetQuery({ query: { id: nftProductId } }, { skip: !nftProductId });

  const [createNftCollection] = useCreateNftCollectionMutation();

  useEffect(() => {
    if (collectionAssets) {
      setFieldValues(parseFormAssetNftCollectionObject(collectionAssets), setValue);
    }
  }, [collectionAssets]);

  useEffect(() => {
    if (productAsset) {
      setValue('productDocument', { fileId: productAsset.id, url: productAsset.key, type: 'productDocument' });
    }
  }, [productAsset]);

  useEffect(() => {
    if (checkName) {
      setError('name', { type: 'focus' }, { shouldFocus: true });
    }
  }, [checkName]);

  const onSubmitHandler = (nftCollection: NftCollectionResponse) => {
    setNftCollectionId(nftCollection.id);
    setNftProductId(nftCollection.product.id);
    setIsSubmitButtonAvailable(false);
    setIsEditDocumentsMode(false);
  };

  return (
    <FormContainer>
      <Row>
        <ControlledInput
          name={'name'}
          placeholder={translate('collectionName')}
          id={'collectionName'}
          onBlur={() => checkIfNftCollectionNameExistsOnBlur(getValues().name)}
        />
        <ErrorMessage>{checkName && translate('nameAlreadyExist')}</ErrorMessage>
        <ControlledInput name={'price'} placeholder={translate('pricePerNft')} id={'price'} />
        <ControlledMultipleSelect name={'regions'} items={regionsAsDropdown} placeholder={translate('availableTo')} />
        <ControlledMultipleSelect
          name={'currencyIds'}
          items={currenciesAsDropdown}
          placeholder={translate('sellInCurrency')}
        />
        {user?.email === 'office@nimbus-tech.io' && (
          <ControlledSelect name={'issuer'} items={tenantUsersAsDropdown} placeholder={translate('issuerName')} />
        )}
      </Row>
      <Row>
        <ControlledInput name={'productTitle'} placeholder={translate('productTitle')} id={'productTitle'} />
        <ControlledInput name={'content1'} placeholder={translate('content1')} id={'content1'} />
        <ControlledInput name={'content2'} placeholder={translate('content2')} id={'content2'} />
        <ControlledInput name={'content3'} placeholder={translate('content3')} id={'content3'} />
        <ControlledInput name={'content4'} placeholder={translate('content4')} id={'content4'} />
        <ControlledInput name={'content5'} placeholder={translate('content5')} id={'content5'} />
      </Row>
      <Row>
        <Documentation
          productAsset={productAsset}
          collectionAssets={collectionAssets}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          isEditMode={isEditDocumentsMode}
        />
      </Row>
      <Row>
        <TextAreaWrapper>
          <ControlledTextarea name={'description'} label={translate('description')} errors={errors} />
        </TextAreaWrapper>
        <TextAreaWrapper>
          <ControlledTextarea name={'germanDescription'} label={translate('germanDescription')} errors={errors} />
        </TextAreaWrapper>
      </Row>
      <Row>
        <ControlledInput name={'bankName'} placeholder={translate('bankName')} id={'bankName'} />
        <ControlledInput name={'bankAddress'} placeholder={translate('bankAddress')} id={'bankAddress'} />
        <ControlledInput name={'iban'} placeholder={translate('iban')} id={'iban'} />
        <ControlledInput name={'swift'} placeholder={translate('swift').toUpperCase()} id={'swift'} />
      </Row>
      <ActionContainer>
        {isSubmitButtonAvailable && (
          <Button
            text={translate('submit')}
            onClick={handleSubmit((data) => {
              createNftCollection(createNftCollectionMapper(data) as CreateNftCollectionRequest).then((response: any) =>
                onSubmitHandler(response.data)
              );
            })}
            disabled={!!Object.keys(errors).length || !Object.keys(dirtyFields).length || !isValid}
            buttonType={'primary'}
            isLoading={false}
            size={'medium'}
          />
        )}
      </ActionContainer>
    </FormContainer>
  );
};
